import axios from 'axios';

const baseDomain = process.env.REACT_APP_MICROSERVICE_API_DOMAIN;
const baseURL = `${baseDomain}/external/course`;

export const MicroService = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
  },
  transformResponse: [(response) => {
    return JSON.parse(response).data;
  }],
});