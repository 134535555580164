import { RouteConfig } from 'routes';
import { Identity } from 'constant';
import { RoutePath } from './routePath';
import { MainLayout } from '../layouts/MainLayout';
import { RecordedCourseVideoPage } from 'views/RecordedCourseVideoPage';
import { LiveCourseVideoPage } from 'views/LiveCourseVideoPage';
import { VideoPlayerPage } from 'views/VideoPlayerPage';
import { CreateCoursePage } from 'views/CreateCoursePage';
import { LoginPage } from 'views/LoginPage';
import { NotFoundPage } from 'views/NotFoundPage';
import { LobbyPage } from 'views/LobbyPage';
import { RoomControl } from 'views/RoomControl';

export const appRoutes: RouteConfig[] = [
  {
    path: RoutePath.Player,
    component: VideoPlayerPage,
    auth: [Identity.All],
    exact: true,
  },
  {
    path: RoutePath.NotFound,
    component: NotFoundPage,
    exact: true,
  },
  {
    path: RoutePath.Login,
    component: LoginPage,
    exact: true,
  },
  {
    path: '',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: RoutePath.RecordedCourseVideoPage,
        component: RecordedCourseVideoPage,
        auth: [Identity.Editor],
        exact: true,
      },
      {
        path: RoutePath.LiveCourseVideoPage,
        component: LiveCourseVideoPage,
        auth: [Identity.Editor],
        exact: true,
      },
      {
        path: RoutePath.LobbyPage,
        component: LobbyPage,
        auth: [Identity.Editor],
        exact: true,
      },
      {
        path: RoutePath.RoomControl,
        component: RoomControl,
        auth: [Identity.Editor],
        exact: true,
      },
      {
        path: RoutePath.Root,
        auth: [Identity.Editor],
        component: CreateCoursePage
      }
    ]
  },
];