import { useContext } from 'react';
import { LiveCourseVideoService } from 'services/LiveCourseVideoService';
import { Card, Row, Col, Alert, Empty } from 'antd';
import ReactPlayer from 'react-player';



export type LiveCourseVideoListProps = {
};

// const videoJsOptions = {
//   autoplay: true,
//   controls: true,
//   responsive: true,
//   fluid: true,
// };

export const LiveCourseVideoList = () => {

  const { data, error, empty } = useContext(LiveCourseVideoService);



  if (error) {
    return (
      <Alert
        message={error.message}
        type="error"
        closable
      />
    );
  }

  if (empty) {
    return <Empty />;
  }

  return (
    <Row gutter={[16, 16]}>
      {
        data && data.map(item => (
          <Col key={item.courseId} span={8}>
            <Card
              bodyStyle={{ padding: 12 }}
              hoverable
            >
              <ReactPlayer
                url={item.live}
                width="100%"
                height="100%"
                muted
                playing
                controls
              />
              <Row>

                <Col span={24}>
                  {item.courseId}
                </Col>
                <Col span={8}>
                  課程名稱：
                </Col>
                <Col span={16}>
                  {item.courseName}
                </Col>

                <Col span={8}>
                  課程類型：
                </Col>
                <Col span={16}>
                  {item.classType}
                </Col>

                <Col span={8}>
                  開始時間：
                </Col>
                <Col span={16}>
                  {item.firstRecordTime}
                </Col>

                <Col span={8}>
                  老師：
                </Col>
                <Col span={16}>
                  {item.teacherName}
                </Col>

                <Col span={8}>
                  學生：
                </Col>
                <Col span={16}>
                  {item.studentName}
                </Col>

              </Row>
            </Card>
          </Col>
        ))
      }
    </Row>
  );
};