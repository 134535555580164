import { UseRequestProvider } from 'ahooks';
import { ThemeProvider } from '@oneclass/onedesign';
import { AuthServiceProvider } from 'services/AuthService';
import { appRoutes } from 'configs/appRoutesConfig';
import { AppRoutes } from './routes';

function App() {
  return (
    <UseRequestProvider value={{
      refreshOnWindowFocus: false,
      throwOnError: true,
    }}>
      <AuthServiceProvider>
        <ThemeProvider>
          <AppRoutes routes={appRoutes}/>
        </ThemeProvider>
      </AuthServiceProvider>
    </UseRequestProvider>
  );
}

export default App;
