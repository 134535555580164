import styled from 'styled-components';

export const StyledVideoPlayer = styled.div`
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .video-js {
    flex: 8;
    min-height: 566px;
  }

  .vjs-tech {
  }

  .vjs-play-progress {
    background-color: #EC7963;
  }

  .vjs-paused {
    .vjs-big-play-button {
      display: block;
    }
  }

  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;