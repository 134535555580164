import { useRef, useState } from 'react';
import { Input, Col, Row, Form, Button } from 'antd';
import { endRoom } from 'services/chime';
import { StyledRoomControl } from './RoomControl.style';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

export const RoomControl = ({ className }) => {
  const [form] = Form.useForm();
  const inputVal = useRef(null);
  const [loading, setLoading] = useState(false);
  const [noticeMsg, setNoticeMsg] = useState(null);

  const endRoomHandler = async () => {
    if (!inputVal || !inputVal.current) return;

    setLoading(true);
    const response = await endRoom({ roomId: inputVal.current });

    if (response.status === 'success') {
      if (response.data?.meetingId) {
        setNoticeMsg('摧毀成功');
      } else {
        setNoticeMsg(response.data.message);
      }
    }
    if (response.status === 'failure') {
      setNoticeMsg(response.error.message);
    }

    setLoading(false);
  };

  const inputChangeHandler = (e) => {
    inputVal.current = e.target.value;
  };

  return (
    <StyledRoomControl className={className} data-testid="RoomControl">
      <Form
        {...layout}
        layout="vertical"
        form={form}
      >
        <Row>
          <Col span={18}>
            <Form.Item
              label="房間id"
              rules={[{ required: true }]}
            >
              <Input onChange={inputChangeHandler} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item {...tailLayout}>
              <Row>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={endRoomHandler}
                >
                  摧毀房間
                </Button>
              </Row>
            </Form.Item>

          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item {...tailLayout}>
              <Row>
                {noticeMsg}
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledRoomControl>
  );
};