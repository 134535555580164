import { useCallback, useState } from 'react';
import { message } from 'antd';
import { Roles, CourseType, ClassStyle } from 'constant';
import { urlFormat, shortId } from 'utils';
import { useCRM } from 'services/CRMService';
import { RepositoryFactory, RepositoryName } from 'repositories/RepositoryFactory';
import { encrypt } from 'utils/crypto';

const secretKey = 'oneclasscool';

const repository = RepositoryFactory[RepositoryName.Course];

export interface ICourseParams {
  dealId?: string;
  fieldId?: string;
  courseName: string,
  classType: CourseType,
  role: Roles,
  teacherName: string,
  studentName: string,
  advisorName: string,
  bookIds?: string,
  autoRecord: string,
  trialStyle: boolean
}

interface ICreateUrlParams extends ICourseParams {
  courseId: string;
}
interface ILobbyCourseParams {
  courseId: string,
  bookIds?: string,
}

const format = (data: ICreateUrlParams) => {
  const formatData = {
    ...data,
    bookIds: (data?.bookIds || '').split(',').map(bookId => bookId.trim())
  };
  return formatData;
};

export const useCreateCourse = () => {
  const { updateDeal } = useCRM();
  const [courseUrls, setCourseUrls] = useState({
    [Roles.Teacher]: '',
    [Roles.Student]: '',
    [Roles.Advisor]: '',
    [Roles.Observer]: ''
  });

  const createUrl = useCallback((data: ICreateUrlParams) => (role: Roles) => {
    const {
      courseId,
      classType,
      teacherName,
      studentName,
      advisorName,
      bookIds,
      autoRecord,
      trialStyle
    } = format(data);


    const courseUrlFormat = ({
      pathname = `${courseId}/setup`,
      query
    }: {
      pathname?: string,
      query: Record<string, string | string[]>
    }) => urlFormat({
      origin: process.env.REACT_APP_ONEBOARD_DOMAIN,
      pathname,
      query
    });

    switch (role) {
      case Roles.Teacher: {
        const query = {
          role,
          classType,
          bookIds,
          autoRecord,
          userName: teacherName,
          userId: shortId(),
        };
        const token = encrypt(JSON.stringify(query), secretKey);
        return courseUrlFormat({
          query: {
            ...query,
            token
          }
        });
      }
      case Roles.Student: {
        let query: Record<string, string | string[]> = {
          role,
          classType,
          userName: studentName,
          userId: shortId(),
        };

        if (trialStyle) {
          query = {
            ...query,
            classStyle: ClassStyle.Trial,
          };
        }
        const token = encrypt(JSON.stringify(query), secretKey);
        return courseUrlFormat({
          query: {
            ...query,
            token
          }
        });
      }
      case Roles.Advisor: {
        const query = {
          role,
          classType,
          bookIds,
          autoRecord,
          userName: advisorName,
          userId: shortId(),
        };
        const token = encrypt(JSON.stringify(query), secretKey);
        return courseUrlFormat({
          query: {
            ...query,
            token
          }
        });
      }
      case Roles.Observer:
      default: {
        return courseUrlFormat({
          pathname: `${courseId}/${Roles.Observer}`,
          query: {
            role,
            classType,
            userName: shortId(),
            userId: shortId()
          }
        });
      }

    }

  }, []);

  const copyText = useCallback((text) => {
    navigator.clipboard.writeText(text).then(function () {
      message.success('複製成功！');
    }, function (err) {
      message.error(`Could not copy text: ${err}`);
    });
  }, [courseUrls]);

  const copyTextByRole = useCallback((role: Roles) => () => {
    return copyText(courseUrls[role]);
  }, [copyText, courseUrls]);

  const generateAllUrlsText = useCallback(({
    [Roles.Teacher]: teacherUrl,
    [Roles.Student]: studentUrl,
    [Roles.Advisor]: advisorUrl,
    [Roles.Observer]: observerUrl,
  }) => {
    return `老師連結： ${teacherUrl}\n` +
      `學生連結： ${studentUrl}\n` +
      `顧問連結： ${advisorUrl}\n` +
      `觀察者連結： ${observerUrl}\n`
    ;
  }, []);

  const copyAllUrls = useCallback(() => {
    copyText(generateAllUrlsText({
      [Roles.Teacher]: courseUrls[Roles.Teacher],
      [Roles.Student]: courseUrls[Roles.Student],
      [Roles.Advisor]: courseUrls[Roles.Advisor],
      [Roles.Observer]: courseUrls[Roles.Observer],
    }));
  }, [copyText, courseUrls]);


  const createCourse = useCallback(async (params: ICourseParams) => {

    const { dealId, fieldId, ...data } = params;
    const { courseId } = await repository.createCourse(data);
    if (!courseId) {
      alert('課程創建失敗');
      return;
    }

    const createUrlByRole = createUrl({
      ...data,
      courseId
    });
    const teacherUrl = createUrlByRole(Roles.Teacher);
    const studentUrl = createUrlByRole(Roles.Student);
    const advisorUrl = createUrlByRole(Roles.Advisor);
    const observerUrl = createUrlByRole(Roles.Observer);

    const urls = {
      [Roles.Teacher]: teacherUrl,
      [Roles.Student]: studentUrl,
      [Roles.Advisor]: advisorUrl,
      [Roles.Observer]: observerUrl
    };

    setCourseUrls(urls);

    if (!dealId || !fieldId) return;
    updateDeal.run({
      id: dealId,
      url: generateAllUrlsText(urls),
      fieldId
    });

  }, [createUrl, generateAllUrlsText]);

  const createLobbyUrl = (data: ILobbyCourseParams) => {
    const { courseId, bookIds } = {
      ...data,
      bookIds: (data?.bookIds || '').split(',').map(bookId => bookId.trim())
    };

    const courseUrlFormat = ({
      pathname = `${courseId}/setup`,
      query
    }: {
      pathname?: string,
      query: Record<string, string | string[]>
    }) => urlFormat({
      origin: process.env.REACT_APP_ONEBOARD_DOMAIN,
      pathname,
      query
    });


    return courseUrlFormat({
      query: {
        role: 'teacher',
        classType: 'sync-multiple',
        bookIds,
        userName: '自習老師',
        userId: 'lobbyTeacher'
      }
    });
  };

  return {
    courseUrls,
    createCourse,
    copyTextByRole,
    copyAllUrls,
    copyText,
    createLobbyUrl
  };
};