import { CRMClient } from './Clients/CRMClient';
import { IDeal, IUser } from 'types/CRM';


interface IUpdateDealParams {
  id: string;
  url: string;
  fieldId: string;
}

export const CRMRepository = {

  getDealById(id: string): Promise<IDeal> {
    return CRMClient.get('/aqw6va2ru41h8d7t/crm.deal.get.json', {
      params: {
        id
      }
    }).then(res => res.data.result);
  },

  updateDeal(params: IUpdateDealParams) {
    const { id, url, fieldId } = params;
    const fields = `FIELDS[${fieldId}]`;
    return CRMClient.get('/sbrsgo8c3qxoxouz/crm.deal.update.json', {
      params: {
        id,
        [fields]: url
      }
    }).then(res => res.data.result);
  },

  getUserById(id: string): Promise<IUser> {
    return CRMClient.get('/f4i801h351wd000p/user.get.json', {
      params: {
        ID: id
      }
    }).then(res => res.data.result[0]);
  }

};
