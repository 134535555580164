import { useRef, useState, useEffect, useMemo } from 'react';
import { Box } from '@oneclass/onedesign';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import { ISource } from 'types/source';
import { StyledVideoPlayer } from './VideoPlayer.style';
import 'video.js/dist/video-js.css';
import { useRecordViewedVideo } from 'usecases/recordViewedVideo';


export type VideoPlayerProps = {
  sources: ISource[];
  controls?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  options?: VideoJsPlayerOptions;
  playlist?: ({ player }: { player: VideoJsPlayer})=> React.ReactNode;
};

const usePlayer = ({
  sources,
  controls = false,
  autoplay = false,
  muted = false,
  options,
}: VideoPlayerProps) => {
  const videoSourceObject = useMemo(() => sources.map(source => ({ src: source.url })), [sources]);
  const playerOptions: VideoJsPlayerOptions = {
    responsive: true,
    preload: 'auto',
    bigPlayButton: true,
    html5: {
      hls: {
        enableLowInitialPlaylist: true,
        smoothQualityChange: true,
        overrideNative: true,
      },
    },
    ...options
  };

  const videoRef = useRef<HTMLVideoElement>(null);
  const [player, setPlayer] = useState<VideoJsPlayer| null>(null);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current as HTMLVideoElement, {
      ...playerOptions,
      controls,
      autoplay,
      muted,
      sources: videoSourceObject,
      liveui: true,
      //播放速度
      playbackRates: [1, 1.5, 2],
    });

    setPlayer(vjsPlayer);

    return () => {
      player?.dispose();
    };
  }, []);

  useEffect(() => {
    player?.src(videoSourceObject);
  }, [videoSourceObject]);


  const { startTimeout, clearTimeout } = useRecordViewedVideo();
  useEffect(() => {
    if (!player) return;
    player.on('playing', () => {
      startTimeout();
    });

    player.on('seeked', () => {
      clearTimeout();
    });
  }, [player]);

  return {
    videoRef,
    player
  };
};


export const VideoPlayer = ({
  sources,
  controls,
  autoplay,
  muted,
  options,
  playlist
}: VideoPlayerProps) => {
  const { videoRef, player } = usePlayer({ sources, controls, autoplay, muted, options });

  return (
    <StyledVideoPlayer data-testid="VideoPlayer">
      <Box display="flex" alignItems="stretch" width="100%">
        <video className="video-js" ref={videoRef} />
        {playlist && player && playlist({ player })}
      </Box>
    </StyledVideoPlayer>
  );
};
