import { useRequest } from 'ahooks';
import { message } from 'antd';
import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sendData as loginApi, checkNaniLinkLogin, getProfile } from './oneClub';
import { createService } from './utils/createService';



export function useAuth() {
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(!!checkNaniLinkLogin());
  const userProfile = useRequest(getProfile, { manual: true });

  const login = useRequest(loginApi, {
    manual: true,
    onSuccess: response => {
      if (response.code === 'FAILED') {
        message.error(response.message, 0);
      } else {
        setIsLogin(true);
      }
    }
  });


  useEffect(() => {
    history.listen(() => {
      const loginInfo = checkNaniLinkLogin();
      if (loginInfo) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!isLogin) return;
    const { jwt } = JSON.parse(checkNaniLinkLogin());
    userProfile.run({ jwt });
  }, [isLogin]);

  return {
    login,
    isLogin,
    userProfile
  };
}

export const AuthService = createService(useAuth);

export const AuthServiceProvider: React.FC = ({ children }) => (
  <AuthService.Provider value={useAuth()}>
    {children}
  </AuthService.Provider>
);

export const useAuthService = () => useContext(AuthService);