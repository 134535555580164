import axios from 'axios';

const BASE_URL = process.env.REACT_APP_CHIME_API_URL;

export const endRoom = async ({ roomId }) => {
  try {
    const response = await axios.post(`${BASE_URL}/end`, null, {
      params: {
        title: roomId,
      }
    });

    return response.data;
  } catch (error) {
    return {
      status: 'failure',
      error: {
        message: error.message
      }
    };
  }
};