import { useRequest } from 'ahooks';
import { RepositoryFactory, RepositoryName } from 'repositories/RepositoryFactory';
import { useParams } from 'react-router-dom';
import { IUrlParams } from 'types/urlParams';
import { createService } from './utils/createService';

const repository = RepositoryFactory[RepositoryName.Course];

export const VideoPlayerService = createService(useVideoPlayerService);

export const VideoPlayerServiceProvider: React.FC = ({ children }) => (
  <VideoPlayerService.Provider value={useVideoPlayerService()}>
    {children}
  </VideoPlayerService.Provider>
);

export function useVideoPlayerService() {
  const { courseId } = useParams<IUrlParams>();
  const { data, loading, error } = useRequest(() =>
    repository.getCourseById(courseId),
  {
    formatResult: (data) => {
      const liveSrc = data.live ? [{ url: data.live }] : [];
      const sources = liveSrc.concat(
        (data.hlsInfo || []).map(info => ({ url: info.url, duration: info.duration }))
      ).filter(item => item.url) || [];
      return {
        ...data,
        sources
      };
    },
    cacheKey: 'VideoPlayerSource',
    refreshDeps: [courseId],
  });

  return {
    data,
    loading,
    empty: !(loading || data?.hlsInfo),
    error,
  };
}