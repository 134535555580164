import queryString from 'query-string';
import { v4 as uuid } from 'uuid';

export const urlFormat = ({
  origin = window.location.origin,
  pathname,
  query
}: {
  origin?: string,
  pathname?: string,
  query: Record<string, string | string[] | number | number[] | undefined>
}) => {
  const url = new URL(origin);
  if (pathname) {
    url.pathname = pathname;
  }
  url.search = queryString.stringify(query, { arrayFormat: 'index' });
  return url.toString();
};


export const shortId = () => uuid().split('-').join('');


export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};