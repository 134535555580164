import { useContext } from 'react';
import { Table, Alert, Empty, Space } from 'antd';
import { RecordedCourseVideoService } from 'services/RecordedCourseVideoService';
import { StyledRecordCourseVideoTable } from './RecordCourseVideoTable.style';


const columns = [
  {
    title: '開始時間',
    dataIndex: 'firstRecordTime',
    key: 'firstRecordTime',
  },
  {
    title: '房間 ID',
    dataIndex: 'courseId',
    key: 'courseId',
  },
  {
    title: '課程名稱',
    dataIndex: 'courseName',
    key: 'courseName',
  },
  {
    title: '課程類型',
    key: 'classType',
    dataIndex: 'classType',
  },
  {
    title: '老師',
    key: 'teacherName',
    dataIndex: 'teacherName',
  },
  {
    title: '學生',
    key: 'studentName',
    dataIndex: 'studentName',
  },
  {
    title: 'Action',
    key: 'action',
    render: function Action(item: any) {
      const href = `${location.origin}/player/${item.courseId}`;
      return (
        <Space size="middle">
          <a href={href} target="_blank" rel="noreferrer">
            View
          </a>
        </Space>
      );
    }
  },
];

export type RecordCourseVideoTableProps = {
};

export const RecordCourseVideoTable = () => {

  const { data, loading, error, empty } = useContext(RecordedCourseVideoService);

  if (error) {
    return (
      <Alert
        message={error.message}
        type="error"
        closable
      />
    );
  }

  if (empty) {
    return <Empty />;
  }

  return (
    <StyledRecordCourseVideoTable data-testid="RecordCourseVideoTable">
      <Table columns={columns} dataSource={data} loading={loading} />
    </StyledRecordCourseVideoTable>
  );
};