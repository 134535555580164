import { RoutePath } from './routePath';

export type Menu = {
  label: string;
  path: string
}

export const menu: Menu[] = [
  {
    label: '創建課程',
    path: RoutePath.Root
  },
  {
    label: '課程錄影',
    path: RoutePath.RecordedCourseVideoPage
  },
  {
    label: '即時課堂',
    path: RoutePath.LiveCourseVideoPage
  },
  {
    label: '自習教室',
    path: RoutePath.LobbyPage
  },
  {
    label: '房間操作',
    path: RoutePath.RoomControl
  }
];