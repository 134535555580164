import styled from 'styled-components';

export const StyledLoginPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  form {
    padding: 40px 40px 5px;
    background: #fff;
    box-shadow: 1px 1px 2px #ccc;
  }
`;