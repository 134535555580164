import { useState } from 'react';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { RepositoryFactory, RepositoryName } from 'repositories/RepositoryFactory';
import { createService } from './utils/createService';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);

const repository = RepositoryFactory[RepositoryName.Course];

export const RecordedCourseVideoService = createService(useRecordedCourseVideoService);

export const RecordedCourseVideoServiceProvider: React.FC<React.ReactNode> = ({ children }) => (
  <RecordedCourseVideoService.Provider value={useRecordedCourseVideoService()}>
    {children}
  </RecordedCourseVideoService.Provider>
);

export function useRecordedCourseVideoService() {
  const [date, setDate] = useState(dayjs());
  const dateString = dayjs(date).format('YYYY/MM/DD');

  const { data, loading, error } = useRequest(() =>
    repository.getRecordedVideo({ date: dateString })
  , {
    formatResult: (data) => data.map(item => ({
      ...item,
      key: item.courseId,
    })),
    cacheKey: 'RecordedCourseVideo',
    refreshDeps: [dateString],
  });

  return {
    data,
    loading,
    empty: !(loading || data?.length),
    error,
    date,
    setDate,
  };
}