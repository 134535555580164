import styled from 'styled-components';

export const StyledMainLayout = styled.div`
  height: 100vh;
`;


export const Logo = styled.div`
  height: 64px;
`;
