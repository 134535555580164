import { useRequest } from 'ahooks';
import { RepositoryFactory, RepositoryName } from 'repositories/RepositoryFactory';

const repository = RepositoryFactory[RepositoryName.CRM];

export const useCRM = () => {

  const getDealById = useRequest(repository.getDealById, { manual: true });

  const updateDeal = useRequest(repository.updateDeal, { manual: true });

  const getUserById = useRequest(repository.getUserById, { manual: true });

  return { getDealById, getUserById, updateDeal };
};