import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AppRoutes, RouteConfig } from 'routes';
import { menu } from 'configs/menu';
import { StyledMainLayout, Logo } from './MainLayout.style';

const { Header, Sider, Content } = Layout;

export type MainLayoutProps = {
  routes: RouteConfig[];
};

export const MainLayout = ({ routes }: MainLayoutProps) => {
  const { pathname } = useLocation();
  return (
    <StyledMainLayout data-testid="MainLayout">
      <Layout>
        <Sider style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}>
          <Logo />
          <Menu theme="dark" mode="inline" selectedKeys={[pathname]}>
            {
              menu.map((menuItem) => (
                <Menu.Item key={menuItem.path} style={{ margin: 0 }}>
                  <Link to={menuItem.path}>
                    {menuItem.label}
                  </Link>
                </Menu.Item>
              ))
            }
          </Menu>
        </Sider>
        <Layout style={{
          marginLeft: 200,
          height: '100vh',
        }}>
          <Header />
          <Content
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            <AppRoutes routes={routes} />
          </Content>
        </Layout>
      </Layout>
    </StyledMainLayout>
  );
};