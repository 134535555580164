import { useState, useEffect } from 'react';
import { Empty, Spin } from 'antd';
import dayjs from 'dayjs';
import { Box } from '@oneclass/onedesign';
import { PlayCircleSolid } from '@onedesign/icon';
import videojs from 'video.js';
import { useVideoPlayerService } from 'services/VideoPlayerService';
import { VideoPlayer } from 'components/VideoPlayer';
import { ISource } from 'types/source';
import { StyledVideoPlayerPage, UiPlaylist, RecordItem, StyledError } from './VideoPlayerPage.style';
import { useMicroService } from 'services/MicroService';
import { useRecordInfo } from 'services/RecordInfo';
import { IUrlParams } from 'types/urlParams';
import { useParams } from 'react-router-dom';
import { ICourseData } from 'types/ICourseData';
import { RecordInfoData } from 'types/RecordInfoData';

export type VideoPlayerPageProps = {
};

type PlaylistItemProps = {
  label: string;
  source: ISource;
  onClick: (source: ISource, index: number)=> void;
  active: boolean;
  chosenIndex: number;
}

interface RecordInfo {
  id: string;
  roomId: string;
  recordTimeout: string;
  createTime: string;
  updateTime: string;
  startedRecordingTime: string;
  stopedRecordingTime: string;
  isRecording: boolean;
}

const PlaylistItem = (
  { label, source, onClick, active, chosenIndex }: PlaylistItemProps
) => {
  const duration = (source?.duration || 0) / 60;
  const isLive = duration ? false : true;
  const clickHandler = () => {
    onClick(source, chosenIndex);
  };

  return (
    <RecordItem active={active} className="recordItem" onClick={clickHandler}>
      <div className="label">{label}</div>
      <div className="duration">
        <Box display="flex" alignItems="center" pr={1}>
          <PlayCircleSolid />
        </Box>
        {
          isLive ? (
            'Live'
          ) : (
            `${duration.toFixed(1)} min`
          )
        }
      </div>
    </RecordItem>
  );
};

type PlaylistProps = {
  courseName: string;
  teacherName: string;
  studentName: string;
  sources: ISource[];
  player: videojs.Player;
  formattedDate?: string;
  formattedStartTime?: string;
  formattedEndTime?: string;
  recordInfo: RecordInfoData;
}


const Playlist = ({
  player,
  sources,
  courseName,
  teacherName,
  studentName,
  formattedDate,
  formattedStartTime,
  formattedEndTime,
  recordInfo,
}: PlaylistProps) => {
  const initialSource = sources[0];
  const id = initialSource?.url?.split('_').pop()?.split('.')[0] || '';
  const [currentSource, setCurrentSource] = useState(sources[0]);
  const [selectedRecordId, setSelectedRecordId] = useState(id);

  const itemClickHandler = (source: ISource) => {
    setCurrentSource(source);
    const urlParts = source.url.split('_');
    const id = urlParts[urlParts.length - 1].split('.')[0];
    setSelectedRecordId(id);
    player.src(source.url);
  };

  const formatRecordingTime = (time: number | undefined) => {
    if (!time) return '';
    const recordingDate = dayjs(Number(time));
    const formattedTime = recordingDate.format('HH:mm:ss');
    return formattedTime;
  };

  const filteredRecordInfo = recordInfo.filter((record: RecordInfo) => {
    const startedRecordingTime = +record.startedRecordingTime;
    const stopRecordingTime = +record.stopedRecordingTime;
    const recordingDuration = stopRecordingTime - startedRecordingTime;
    const recordingMinutes = recordingDuration / 60000;
    return recordingMinutes >= 5;
  });

  const currentRecord = filteredRecordInfo.find((record: { id: string; }) => record.id === selectedRecordId);
  const startedRecordingTime = currentRecord?.startedRecordingTime;
  const stopRecordingTime = currentRecord?.stopedRecordingTime;

  return (
    <UiPlaylist ml="4">
      <div className="title">{courseName}</div>
      <div className="courseInfo">
        <div className="courseInfoItem">
          <div className="label">老師</div>
          <div className="value">{teacherName}</div>
        </div>
        <div className="courseInfoItem">
          <div className="label">學生</div>
          <div className="value">{studentName}</div>
        </div>
        <div className="courseInfoItem">
          <div className="label">上課日期</div>
          <div className="value">{formattedDate}</div>
        </div>
        <div className="courseInfoItem">
          <div className="label">開課時間</div>
          <div className="value">{formattedStartTime}-{formattedEndTime}</div>
        </div>
        <div className="courseInfoItem">
          <div className="label">錄影時間</div>
          <div className="value">{formatRecordingTime(startedRecordingTime)}-{formatRecordingTime(stopRecordingTime)}</div>
        </div>
      </div>
      <Box className="title" pl="2">上課記錄</Box>
      <div className="record">
        {
          sources.map((source, index) => (
            <PlaylistItem
              key={index}
              label={`Record ${index + 1}`}
              source={source}
              chosenIndex={index}
              active={currentSource.url === source.url}
              onClick={() => itemClickHandler(source)}
            />
          ))
        }
      </div>
    </UiPlaylist>
  );
};

export const Error = () => {

  return (
    <StyledError>
      <Box
        alignItems="center"
        justifyContent="center"
        width="600px"
        maxWidth="100%"
      >
        <div className="icon">
          <img src="/images/warning.svg" alt="" />
        </div>
        <div className="title">無法存取影片資料</div>
        <div className="content">
          <div className="text">可能原因：</div>
          <div className="text">錄影檔案正在生成中，請稍後再試</div>
          <div className="text">因其他錯誤原因導致無影片資料紀錄</div>
        </div>
      </Box>
    </StyledError>
  );
};

export const VideoPlayerPage = () => {
  const { courseId } = useParams<IUrlParams>();
  const { data, error, empty, loading } = useVideoPlayerService();
  const { getCourseData } = useMicroService();
  const { getRecordInfo } = useRecordInfo();
  const { data: courseData, loading: courseLoading } = getCourseData as unknown as { data: ICourseData, loading: boolean };
  const { data: recordInfo, loading: recordLoading } = getRecordInfo as unknown as { data: RecordInfoData, loading: boolean };
  const studentsName = courseData?.students?.map((student: { name: string }) => student.name).join('、');

  const formatDate = (dateValue: string | number | Date) => {
    if (!dateValue) return '';
    const dateTime = dayjs(dateValue);
    const formattedDate = dateTime.format('YYYY/MM/DD');
    return formattedDate;
  };

  const formatTime = (timeValue: string | number | Date) => {
    if (!timeValue) return '';
    const dateTime = dayjs(timeValue);
    const formattedTime = dateTime.format('HH:mm');
    return formattedTime;
  };

  const formattedDate = formatDate(courseData?.startAt);
  const formattedStartTime = formatTime(courseData?.startAt);
  const formattedEndTime = formatTime(courseData?.endAt);

  useEffect(() => {
    getCourseData.run(courseId);
    getRecordInfo.run(courseId);
  }, [courseId]);

  if (error) {
    return (
      <Error />
    );
  }

  if (data?.hlsInfo?.length === 0 && data?.mp4Info?.length === 0 && !data?.live) {
    return (
      <Error />
    );
  }

  return (
    <StyledVideoPlayerPage data-testid="VideoPlayerPage">
      {
        loading || courseLoading || recordLoading ? <Spin size="large" /> : (empty || !data) ? (
          <Empty />
        ) : (
          <VideoPlayer
            sources={data.sources}
            controls
            autoplay
            playlist={({ player }) => (
              <Playlist
                courseName={courseData?.name}
                teacherName={courseData?.teacher?.fullName}
                studentName={studentsName}
                player={player}
                sources={data.sources}
                formattedDate={formattedDate}
                formattedStartTime={formattedStartTime}
                formattedEndTime={formattedEndTime}
                recordInfo={recordInfo}
              />
            )}
          />
        )
      }
    </StyledVideoPlayerPage>
  );
};