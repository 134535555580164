import { useEffect } from 'react';
import { Input, Form, Button, Row, Col, Select, Checkbox } from 'antd';
import { CourseType, Roles, CRMField, CRMFieldMap } from 'constant';
import { Box } from '@oneclass/onedesign';
import { useCreateCourse } from 'usecases/createCourse.usecase';
import { useCRM } from 'services/CRMService';
import { StyledCreateCoursePage } from './CreateCoursePage.style';
import { IDeal } from 'types/CRM';

export const CourseTypeMap = {
  [CourseType.Single]: '1 v 1',
  [CourseType.Group]: '1 v 6',
  [CourseType.Multiple]: '1 v more',
  [CourseType.SyncSingle]: 'agora 1 v 1',
  [CourseType.SyncMultiple]: 'agora 1 v more',
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};


export type CreateCoursePageProps = {
};

export type CRMForm = {
  onDealChange: (data: IDeal, fieldId: string)=> void;
}
const CRMForm = ({ onDealChange }: CRMForm) => {
  const { getDealById } = useCRM();
  const [form] = Form.useForm();
  const { data, loading, run } = getDealById;

  const getDealHandler = (value: { dealId: string}) => {
    const { dealId } = value;
    run(dealId);
  };

  useEffect(() => {
    if (!data) return;
    onDealChange(data, form.getFieldValue('fieldId'));
  }, [getDealById.data]);

  return (
    <Row justify="center" align="middle">
      <Col span={18}>
        <Form
          {...layout}
          layout="vertical"
          onFinish={getDealHandler}
          form={form}
        >
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                label="CRM 訂單編號"
                name="dealId"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="視聽資訊"
                name="fieldId"
                initialValue={CRMField.fieldId_1}
              >
                <Select>
                  {
                    Object.values(CRMField).map((item, index) => (
                      <Select.Option key={item} value={item}>
                          視聽資訊 {index + 1}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Box display="flex" alignItems="center" height="100%">
                <Button type="primary" htmlType="submit" loading={loading}>
                    取出資訊
                </Button>
              </Box>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export const CreateCoursePage = () => {
  const { getUserById } = useCRM();
  const { createCourse, courseUrls, copyAllUrls, copyTextByRole } = useCreateCourse();
  const [form] = Form.useForm();

  const dealChangeHandler = (dealData: IDeal, fieldId: string) => {
    const {
      ID,
      TITLE,
      ASSIGNED_BY_ID,
    } = dealData;

    const teacherNameField = CRMFieldMap[fieldId].teacherName as keyof IDeal;
    const teacherName = dealData[teacherNameField];

    const bookIdsField = CRMFieldMap[fieldId].bookIds as keyof IDeal;
    const bookIds = dealData[bookIdsField];

    const studentNameField = CRMFieldMap[fieldId].studentName as keyof IDeal;
    const studentName = dealData[studentNameField];

    getUserById.run(ASSIGNED_BY_ID);

    form.setFieldsValue({
      dealId: ID,
      fieldId,
      courseName: TITLE,
      teacherName,
      studentName,
      bookIds
    });
  };

  useEffect(() => {
    const { data } = getUserById;
    if (!data) return;
    form.setFieldsValue({
      advisorName: data.NAME
    });

  }, [getUserById.data]);

  return (
    <StyledCreateCoursePage data-testid="CreateCoursePage">
      <CRMForm onDealChange={dealChangeHandler} />
      <Row justify="center">
        <Col span={18}>
          <Form
            {...layout}
            layout="vertical"
            onFinish={createCourse}
            form={form}
          >
            <Form.Item name="dealId" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="fieldId" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label="課程名稱"
              name="courseName"
              rules={[{ required: true, message: '請輸入課程名稱' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="課程類型"
              name="classType"
              initialValue={CourseType.Single}
              rules={[{ required: true, message: '請選擇課程類型' }]}
            >
              <Select>
                {
                  Object.values(CourseType).map(type => (
                    <Select.Option key={type} value={type}>{CourseTypeMap[type]}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item
                  label="試聽課程"
                  name="trialStyle"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="自動錄影"
                  name="autoRecord"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item
                  label="老師姓名"
                  name="teacherName"
                  rules={[{ required: true, message: '請輸入老師姓名' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="學生姓名"
                  name="studentName"
                  rules={[{ required: true, message: '請輸入學生姓名' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="顧問姓名"
                  name="advisorName"
                  rules={[{ required: true, message: '請輸入顧問姓名' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="書本Id"
              name="bookIds"
              rules={[{ required: false, message: '請輸入老師姓名' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Row justify="center">
                <Button type="primary" htmlType="submit">
                產生連結
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={18}>
          <Input.Search
            addonBefore="老師連結"
            value={courseUrls[Roles.Teacher]}
            placeholder="input search text"
            enterButton="複製"
            size="large"
            onSearch={copyTextByRole(Roles.Teacher)}
          />
        </Col>
        <Col span={18}>
          <Input.Search
            addonBefore="學生連結"
            value={courseUrls[Roles.Student]}
            placeholder="input search text"
            enterButton="複製"
            size="large"
            onSearch={copyTextByRole(Roles.Student)}
          />
        </Col>
        <Col span={18}>
          <Input.Search
            addonBefore="顧問連結"
            value={courseUrls[Roles.Advisor]}
            placeholder="input search text"
            enterButton="複製"
            size="large"
            onSearch={copyTextByRole(Roles.Advisor)}
          />
        </Col>
        <Col span={18}>
          <Input.Search
            addonBefore="觀察者連結"
            value={courseUrls[Roles.Observer]}
            placeholder="input search text"
            enterButton="複製"
            size="large"
            onSearch={copyTextByRole(Roles.Observer)}
          />
        </Col>
        <Col span={10}>
          <Row justify="center">
            <Button type="primary" onClick={copyAllUrls}>複製全部</Button>
          </Row>
        </Col>
      </Row>
    </StyledCreateCoursePage>
  );
};