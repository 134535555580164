import React, { useMemo } from 'react';
import { RoutePath } from 'configs/routePath';
import { Identity } from 'constant';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuthService } from 'services/AuthService';

export type RouteConfig = {
  path: string;
  // eslint-disable-next-line
  component: any,
  auth?: Identity[],
  exact?: boolean,
  routes?: RouteConfig[]
}

const ProtectedRoute = ({ auth, children }: { auth: Identity[], children: React.ReactElement }) => {
  const { isLogin, userProfile } = useAuthService();



  const profile = userProfile.data;

  const isAuth = useMemo(() => {
    const identityAuth = auth.includes(profile?.identity) || auth.includes(Identity.All);
    return auth && identityAuth && isLogin;
  }, [profile, isLogin]);

  if (!isLogin) {
    return <Redirect to={RoutePath.Login} />;
  }

  if (userProfile.loading || !profile) {
    return <Spin size="large" />;
  }

  if (!isAuth) {
    return <Redirect to={RoutePath.NotFound} />;
  }

  return children;
};

const RouteWithSubRoutes = (route: RouteConfig) => route.auth ? (
  <ProtectedRoute auth={route.auth}>
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        <route.component {...props} {...route} routes={route.routes} />
      )}
    />
  </ProtectedRoute>
) : (
  <Route
    path={route.path}
    exact={route.exact}
    render={(props) => (
      <route.component {...props} {...route} routes={route.routes} />
    )}
  />
);

export const AppRoutes = ({ routes = [] }: { routes: RouteConfig[] }) => {
  return (
    <Switch>
      {
        routes.map((route, index) =>
          <RouteWithSubRoutes
            key={index}
            {...route}
          />
        )
      }
    </Switch>
  );
};
