import { CourseClient } from './Clients/CourseClient';
import { CourseType } from 'constant';
import { ISource } from 'types/source';
interface ICreateCourseParams {
  courseName: string;
  teacherName: string;
  studentName: string;
  advisorName: string;
  classType: CourseType;
  bookIds?: string;
}
interface ICourse {
  courseId: string;
  courseName: string;
  teacherName: string;
  studentName: string;
  advisorName: string;
  classType: CourseType;
  bookIds?: string;
  createTime: string;
  firstRecordTime: string;
  lastRecordTime: string;
  live?: string;
  mp4Info?: ISource[];
  hlsInfo?: ISource[];
}

interface UpdateUserEventParams {
  courseId: string;
  userId: string;
  eventName: string;
}

export const CourseRepository = {
  async createCourse(
    params: ICreateCourseParams
  ): Promise<{ courseId: string }> {
    return CourseClient.post('', params).then((res) => res.data);
  },

  async getCourseById(courseId: string): Promise<ICourse> {
    return CourseClient.get('', {
      params: {
        courseId,
      },
    }).then((res) => res.data);
  },

  async getRecordingVideo(): Promise<ICourse[]> {
    return CourseClient.get('/recording').then((res) => res.data);
  },

  async getRecordedVideo(params: { date: string }): Promise<ICourse[]> {
    const { date } = params;
    return CourseClient.get('/record', {
      params: {
        date,
      },
    }).then((res) => res.data);
  },

  async updateUserEvent({ courseId, userId, eventName }: UpdateUserEventParams) {
    return CourseClient.post('/plusUserEvent', {
      courseId,
      userId,
      eventName,
    }).then((res) => res.data);
  },
};
