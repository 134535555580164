import axios from 'axios';

const baseURL = 'https://oneclass.bitrix24.com/rest/9';

export const CRMClient = axios.create({
  baseURL,
  headers: {
    // "Authorization": "Bearer xxxxx"
  },
  transformResponse: [(response) => {
    // Do whatever you want to transform the data
    return JSON.parse(response);
  }],
});