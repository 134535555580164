import { useState } from 'react';
import { useTimeout, useCookieState } from 'ahooks';
import { RepositoryFactory, RepositoryName } from 'repositories/RepositoryFactory';
import { useParams } from 'react-router-dom';
import { IUrlParams } from 'types/urlParams';
import { parseJwt } from 'utils';

const repository = RepositoryFactory[RepositoryName.Course];

export const useRecordViewedVideo = () => {
  const [cookie] = useCookieState('nani_oneclass_login_token');
  const { jwt } = JSON.parse(cookie || '{}');
  const { username: userId } = parseJwt(jwt);

  const [delay, setDelay] = useState<number | null>();
  const startTimeout = () => setDelay(3 * 60 * 1000);
  const clearTimeout = () => setDelay(null);

  const { courseId } = useParams<IUrlParams>();
  useTimeout(() => {
    repository.updateUserEvent({
      courseId,
      userId,
      eventName: 'viewedRecordVideo'
    });
    clearTimeout();
  }, delay);

  return {
    startTimeout,
    clearTimeout
  };
};