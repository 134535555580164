import styled from 'styled-components';
import { Box } from '@oneclass/onedesign';

export const StyledVideoPlayerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #2D2F35;
`;



export const UiPlaylist = styled(Box)`
  flex: 2;
  align-self: stretch;
  background: #242731;
  border-radius: 10px;
  padding: 20px 16px;
  cursor: pointer;

  > .title {
    color: #fff;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 28px;
  }

  > .courseInfo {
    margin: 24px 0;
    padding: 16px;
    border-radius: 10px;
    background-color: #2A2D36;

    > .courseInfoItem {
      display: flex;
      flex-wrap: nowrap;
      gap: 20px;

      & + .courseInfoItem {
        margin-top: 8px;
      }

      > .label {
        width: 56px;
        color: #A1A4B1;
        flex-shrink: 0;
      }
  
      > .value {
        width: fit-content;
        color: #FAFAFC;
      }
    }

  }

  > .record {
    color: #A1A4B1;
  }
`;

export const RecordItem = styled.div<{active: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: ${({ active }) => active ? '#EC7963' : 'transparent'};
  color: ${({ active }) => active ? '#fff' : '#A1A4B1'};

  > .label {
    font-size: 14px;
  }

  .duration {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
`;

export const StyledError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: #F2F4F8;
  background-color: #1F2128;

  .icon {
    text-align: center;
  }

  .title {
    margin: 42px 0 32px;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }

  .content {
    padding: 32px 168px;
    background-color: #242731;
    font-size: 16px;
    line-height: 36px;
  }
`;