import { useContext } from 'react';
import { Space } from 'antd';
import { DatePicker } from 'components/DatePicker';
import { RecordCourseVideoTable } from 'containers/RecordCourseVideoTable';
import { RecordedCourseVideoServiceProvider, RecordedCourseVideoService } from 'services/RecordedCourseVideoService';
import { StyledRecordedCourseVideoPage } from './RecordedCourseVideoPage.style';

export const RecordedCourseVideo = () => {
  const { date, setDate } = useContext(RecordedCourseVideoService);

  return (
    <StyledRecordedCourseVideoPage data-testid="RecordedCourseVideoPage">
      <Space direction="vertical" style={{ width: '100%' }}>
        <DatePicker
          value={date}
          format="YYYY/MM/DD"
          onChange={(date) => date && setDate(date)}
          disabledDate={current => current && current.valueOf() >= Date.now()}
        />
        <RecordCourseVideoTable />
      </Space>
    </StyledRecordedCourseVideoPage>
  );
};


export const RecordedCourseVideoPage = () => {
  return (
    <RecordedCourseVideoServiceProvider>
      <RecordedCourseVideo />
    </RecordedCourseVideoServiceProvider>
  );
};