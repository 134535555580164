export enum CourseType {
  Single = 'single',
  Group = 'group',
  Multiple = 'multiple',
  SyncSingle = 'sync-single',
  SyncMultiple = 'sync-multiple',
}

export enum Roles {
  Teacher = 'teacher',
  Student = 'student',
  Advisor = 'advisor',
  Observer = 'observer'
}


export enum Identity {
  Editor = '編輯',
  Teacher = '教師',
  Parent = '家長',
  Student = '學生',
  All = 'all'
}




export const CRMField = {
  fieldId_1: 'UF_CRM_1624445472',
  fieldId_2: 'UF_CRM_1624445493',
  fieldId_3: 'UF_CRM_1624445511',
  fieldId_4: 'UF_CRM_1624445526',
};

export const CRMFieldMap = {
  [CRMField.fieldId_1]: {
    teacherName: 'UF_CRM_1620125452417',
    studentName: 'UF_CRM_1628646513',
    bookIds: 'UF_CRM_1628646299',
  },
  [CRMField.fieldId_2]: {
    teacherName: 'UF_CRM_1623036815',
    studentName: 'UF_CRM_1628646522',
    bookIds: 'UF_CRM_1628646309',
  },
  [CRMField.fieldId_3]: {
    teacherName: 'UF_CRM_1623036840',
    studentName: 'UF_CRM_1628646544',
    bookIds: 'UF_CRM_1628646319',
  },
  [CRMField.fieldId_4]: {
    teacherName: 'UF_CRM_1623036853',
    studentName: 'UF_CRM_1628646553',
    bookIds: 'UF_CRM_1628646328',
  }
};

export const ClassStyle = {
  Normal: 'normal',
  Trial: 'trial'
};