import { Form, Input, Button, Col } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthService } from 'services/AuthService';
import { StyledLoginPage } from './LoginPage.style';

export type LoginPageProps = {
};

export const LoginPage = () => {
  const { login, isLogin } = useAuthService();
  const history = useHistory();

  useEffect(() => {
    if (!isLogin) return;
    history.push('/');
  }, [isLogin]);

  const onFinish = ({
    username, password
  }: { username: string; password: string}) => {
    login.run(username, password);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <StyledLoginPage data-testid="LoginPage">
      <Col span={4}>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={login.loading}>
                Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </StyledLoginPage>
  );
};