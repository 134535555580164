import { LiveCourseVideoServiceProvider } from 'services/LiveCourseVideoService';
import { LiveCourseVideoList } from 'containers/LiveCourseVideoList';
import { StyledLiveCourseVideoPage } from './LiveCourseVideoPage.style';

export type LiveCourseVideoPageProps = {
};

export const LiveCourseVideoPage = () => {
  return (
    <LiveCourseVideoServiceProvider>
      <StyledLiveCourseVideoPage data-testid="LiveCourseVideoPage">
        <LiveCourseVideoList />
      </StyledLiveCourseVideoPage>
    </LiveCourseVideoServiceProvider>
  );
};