import { useState } from 'react';
import { Input, Col, Row, Form, Button } from 'antd';
import { useCreateCourse } from 'usecases/createCourse.usecase';
import dayjs from 'dayjs';
import { StyledLobbyPage } from './LobbyPage.style';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

export type LobbyPageProps = {
};

export const LobbyPage = () => {
  const [form] = Form.useForm();
  const { copyText, createLobbyUrl } = useCreateCourse();
  const [lobbyUrl, setLobbyUrl] = useState('');

  const createCourse = (params: { bookIds: string}) => {
    const { bookIds } = params;
    const lobbyRoomId = `${dayjs().format('YYYYMMDD')}room`;
    const lobbyRoomUrl = createLobbyUrl({
      courseId: lobbyRoomId,
      bookIds
    });

    setLobbyUrl(lobbyRoomUrl);
  };

  return (
    <StyledLobbyPage data-testid="LobbyPage">
      <Form
        {...layout}
        layout="vertical"
        onFinish={createCourse}
        form={form}
      >
        <Row>
          <Col span={18}>
            <Form.Item
              label="教材id"
              name="bookIds"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item {...tailLayout}>
              <Row justify="center">
                <Button type="primary" htmlType="submit">
                產生連結
                </Button>
              </Row>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={18}>
            <Input.Search
              addonBefore="上課連結"
              value={lobbyUrl}
              placeholder="input search text"
              enterButton="複製"
              size="large"
              onSearch={copyText}
            />
          </Col>
        </Row>
      </Form>
    </StyledLobbyPage>
  );
};