import { useRequest } from 'ahooks';
import { RepositoryFactory, RepositoryName } from 'repositories/RepositoryFactory';
import { createService } from './utils/createService';

const repository = RepositoryFactory[RepositoryName.Course];

export const LiveCourseVideoService = createService(useLiveCourseVideoService);

export const LiveCourseVideoServiceProvider: React.FC = ({ children }) => (
  <LiveCourseVideoService.Provider value={useLiveCourseVideoService()}>
    {children}
  </LiveCourseVideoService.Provider>
);

export function useLiveCourseVideoService() {
  const { data, loading, error } = useRequest(repository.getRecordingVideo, {
    pollingInterval: 30000
  });

  return {
    data,
    loading,
    empty: !(loading || data?.length),
    error,
  };
}