export const RoutePath = {
  Root: '/',
  NotFound: '/not-found',
  Login: '/login',
  Player: '/player/:courseId',
  RecordedCourseVideoPage: '/recorded-course-video',
  LiveCourseVideoPage: '/live-course-video',
  CreateCoursePage: '/create-course',
  LobbyPage: '/lobby-page',
  RoomControl: '/RoomControl'
};