import { CourseRepository } from './CourseRepository';
import { CRMRepository } from './CRMRepository';

export enum RepositoryName {
  Course = 'Course',
  CRM = 'CRM'
}

export const RepositoryFactory = {
  [RepositoryName.Course]: CourseRepository,
  [RepositoryName.CRM]: CRMRepository,
};
