import axios from 'axios';

const baseDomain = process.env.REACT_APP_RECORD_API_DOMAIN;
const baseURL = `${baseDomain}/oneboard/course`;

export const CourseClient = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    // "Authorization": "Bearer xxxxx"
  },
  transformResponse: [(response) => {
    // Do whatever you want to transform the data
    return JSON.parse(response).data;
  }],
});