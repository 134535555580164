import axios from 'axios';

const baseDomain = process.env.REACT_APP_EXECUTE_API_DOMAIN;
const baseURL = `${baseDomain}/Prod/agora/get_recording_times`;

export const RecordInfo = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    'content-type': 'application/json'
  },
  transformResponse: [(response) => {
    return JSON.parse(response).data;
  }],
});